import React, { useState } from 'react'
import { notification } from 'antd'
import { useTranslation } from 'lib/i18n'
import { getItem, setItem } from 'lib/localStorage'
import trackNewsletterSubscription from 'lib/tracking/avo/trackNewsletterSubscription'

import EmailSignupForm from 'components/EmailSignupForm/EmailSignupForm'
import useCustomerData from 'hooks/useCustomerData'
import useEffectOnce from 'hooks/useEffectOnce'
import useNewsletterSubscribe from 'hooks/useNewsletterSubscribe'

import styles from './NewsletterPopup.module.css'

const percent = 10
const isNewsletterNotificationSeen = 'isNewsletterNotificationSeen'
const key = 'newsletterPopup'

const setNewsletterNotificationSeen = () =>
  setItem(isNewsletterNotificationSeen, 'true')
const getRelativeScroll = () => window.pageYOffset / document.body.scrollHeight

// The wrapper is there to have the state inside the notification, which isn't tied to the parent component
const NotificationWrapper = () => {
  const { t } = useTranslation()
  const customerData = useCustomerData()
  const [done, setDone] = useState(false)

  const { newsletterSubscribe } = useNewsletterSubscribe({
    onCompleted: () => {
      setDone(true)
    },
  })

  const [couponCode, setCouponCode] = useState('')

  return (
    <EmailSignupForm
      loading={false}
      done={done}
      onFinish={async (input: { email: string }) => {
        const subscribeResult = await newsletterSubscribe(input)
        setCouponCode(subscribeResult.couponCode)
        if (customerData) {
          customerData.logEvent('newsletter-subscribe', { email: input.email })
          customerData.modify({
            email: input.email,
            subscriptionType: 'opted_in',
          })
        }
        trackNewsletterSubscription()
      }}
      couponCode={couponCode}
      heading={t('EmailSignupForm.title', 'Newsletter')}
      text={t(
        'EmailSignupForm.infoText',
        'Get real time updates and offers from memmo. Subscribe to get a {{percent}}% discount on your next order.',
        { percent },
      )}
      isPopup
    />
  )
}

const NewsletterPopup = (): JSX.Element => {
  const [notificationApi, contextHolder] = notification.useNotification()

  const onScroll = (): void => {
    if (!getItem(isNewsletterNotificationSeen) && getRelativeScroll() >= 0.2) {
      notificationApi.open({
        message: <></>,
        description: <NotificationWrapper />,
        placement: 'bottomLeft',
        duration: 0,
        key,
        className: styles.notification,
      })

      setNewsletterNotificationSeen()
    }
  }

  useEffectOnce(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  return contextHolder
}

export default NewsletterPopup
